.contentWrapper,
.backButtonWrapper {
  max-width: 1280px;
  display: flex;
  justify-content: center;
}

.backButtonWrapper {
  margin: 20px;
  margin-bottom: -15px;
  align-items: center;
}

.contentWrapper > * {
  flex-grow: 1;
}
