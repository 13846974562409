@import url("normalize.css");

body {
  font-weight: 400;
  min-width: 320px;
}

/* stylelint-disable */
.visually-hidden:not(:focus):not(:active),
input[type="checkbox"].visually-hidden,
input[type="radio"].visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}
/* stylelint-enable */

:root {
  --greenColor: #007041;
  --darkGrey: #3d3935;
  --coldGrey: #212529;
  --grey: #6c757d;
  --lightGrey: #c7ced3;
  --lightBlue: #f8f9fa;
  --lightGreen: #e9ecef;
  --white: #fff;
  --error: #d14931;
  --yellow: #ff9f05;
  --titleMarginBottom: 20px;
  --inputMarginBottom: 28px;
  --skeletonGradient: linear-gradient(
    to right,
    transparent 0%,
    var(--white) 100%
  );
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 100px;
  color: var(--coldGrey);
}

.link {
  color: var(--coldGrey);
  text-decoration: underline;
  font-weight: 700;
}
.link:hover {
  color: var(--coldGrey);
}

/* скрываем стрелки у input type number
Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
}

/* stylelint-disable */
.invalid-feedback {
  font-size: 13px;
  word-wrap: break-word;
  position: absolute;
  top: 100%;
  z-index: 9;
  margin: 0;
  background-color: var(--lightGreen);
  color: var(--error);
  width: 105%;
  text-align: left;
}

[data-type="collapse"] .invalid-feedback {
  background-color: var(--white);
}

.ant-input-status-error,
.ant-select-status-error div {
  border-color: var(--error) !important;
}

/* stylelint-enable */

.basic-wrapper {
  background-color: var(--white);
  padding: 15px;
  box-shadow: 0 0 30px -14px rgb(34 60 80/ 20%);
}

/* scrollbar style */
body *::-webkit-scrollbar-thumb {
  background-color: var(--greenColor);
  border-radius: 10px;
}

body *::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

/* стиль для отображения трех строк */
.cropText {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.skeletonGradient {
  background-size: 200% 100%;
  background-attachment: fixed;
  background-image: var(--skeletonGradient);
  animation: skeleton-gradient-animation 1s linear infinite;
}

.ant-picker-dropdown {
  z-index: 9999;
}

/* для переноса input на новую строку */
.ant-form-item-row {
  display: block !important;
}

.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 25px;
}

input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

.contentWrapper {
  margin: 20px;
}

.ant-upload-list-item-container {
  max-width: 480px;
}

.slick-slide {
  padding: 0 10px;
}
