.title {
  font-weight: 700;
  text-align: left;
  margin: 0;
  padding: 0;
  color: var(--coldGrey);
}

.large {
  font-size: 25px;
  line-height: 90px;
}

.medium {
  font-size: 20px;
  line-height: 54px;
}

.small {
  font-size: 18px;
  line-height: 40px;
}

.extraSmall {
  font-size: 16px;
  line-height: 28px;
}

@media screen and (max-width: 768px) {
  .large {
    font-size: 20px;
    line-height: 42px;
  }

  .medium {
    font-size: 18px;
    line-height: 42px;
  }

  .small {
    font-size: 16px;
    line-height: 32px;
  }
}
