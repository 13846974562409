.container {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
  width: 100%;
}

.spinner {
  border: 1.1em solid rgb(0 112 65 / 20%);
  border-left: 1.1em solid var(--greenColor);
  border-radius: 50%;
  animation: load 1.1s infinite linear;
  transition: all 0.3s;
}

.spinnerLg {
  width: 5em;
  height: 5em;
  border-width: 1.1em;
}

.spinnerMd {
  width: 2.5em;
  height: 2.5em;
  border-width: 0.6em;
}

.spinnerSm {
  width: 1.5em;
  height: 1.5em;
  border-width: 0.3em;
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
