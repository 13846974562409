.cardsWrapper {
  max-width: 1280px;
  overflow-x: auto;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 20px 0;
}

.cardsWrapper > div {
  flex-grow: 1;
}

@media screen and (max-width: 768px) {
  .cardsWrapper {
    max-width: 700px;
  }
}

.actionsWrapper {
  text-align: right;
}

.commonDate {
  font-size: 13px;
  font-weight: 700;
  color: var(--grey);
  text-align: left;
  line-height: 20px;
  margin-top: -8px;
}
