.card {
  cursor: pointer;
  user-select: none;
}

.iconWrapper {
  padding: 10px;
  background-color: var(--error);
  color: var(--white);
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.cardContent {
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: left;
}

.title {
  font-weight: 700;
  color: var(--coldGrey);
}

.addData {
  font-size: 13px;
  font-weight: 700;
  color: var(--grey);
}
