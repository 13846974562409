.headerWrapper {
  display: flex;
  align-items: center;
  height: 70px;
  gap: 40px;
}

.logoLink {
  display: flex;
}
